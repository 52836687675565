import React from 'react'
import DropdownItem from './uiKit/DropdownItem'
import DropdownDivider from './uiKit/DropdownDivider'




class CoreHelpDropDownItems extends React.Component{

	render(){	

		const {service,meta,onboardingWizardDismissed}=this.props

		return(		
			<React.Fragment>

				<DropdownItem label='Plickers Help Home' onSelect={()=>{window.open('https://help.plickers.com','_blank')}} />
				<DropdownItem label='Getting Started Guide' onSelect={()=>{window.open('https://help.plickers.com/hc/en-us/categories/1260801472210-Getting-Started','_blank')}} />
				<DropdownItem label='Get Plickers Cards' onSelect={()=>{window.open('https://help.plickers.com/hc/en-us/articles/360008948034-Get-Plickers-Cards','_blank')}} />
				<DropdownItem label='Get iOS App' onSelect={()=>{window.open('https://itunes.apple.com/us/app/plickers/id701184049?mt=8','_blank')}} />
				<DropdownItem label='Get Android App' onSelect={()=>{window.open('https://play.google.com/store/apps/details?id=com.plickers.client.android','_blank')}} />
				<DropdownDivider />
				<DropdownItem label="E-Learning Help" onSelect={()=>{window.location.hash='e-learning-help'}} />	
				<DropdownDivider />
				{/*
				<DropdownItem label="What's New" onSelect={()=>{window.location.hash='whats-new'}} />	
				*/}		
				{meta && meta.onboardingStatus && !meta.onboardingStatus.assignedResponseScanned && onboardingWizardDismissed &&
					<React.Fragment>
						<DropdownItem label='Show Setup Wizard' onSelect={this.props.showOnboardingWizard} />	
						<DropdownDivider />
					</React.Fragment>
				}	
				{meta && meta.onboardingStatus && !meta.onboardingStatus.assignedResponseScanned && !onboardingWizardDismissed &&
					<React.Fragment>
						<DropdownItem label='Hide Setup Wizard' onSelect={this.props.dismissOnboardingWizard} />	
						<DropdownDivider />
					</React.Fragment>
				}



				{service && service.product ==='free' &&
					<DropdownItem label='Upgrade to Pro' onSelect={()=>{window.location.hash='upgrade'}} />				
				}				
				<DropdownItem label="What's New" onSelect={()=>{window.location.hash='whats-new'}} />				
				<DropdownItem label='Contact Plickers' onSelect={()=>{window.open('https://help.plickers.com/hc/en-us/articles/360008951294-write-to-us','_blank')}} />			
			</React.Fragment>
			
				
			
		)
	}
}



export default CoreHelpDropDownItems

			
