import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import { reducer as form } from 'redux-form'

// User/Account/Upgrade
import user from './user'
import userConfig from './userConfig'
import session from './session'
import signinMethods from './signinMethods'
import meta from './meta'
import service from './service'
/* eslint-disable import/no-cycle */
import planRestrictions from './planRestrictions'
/* eslint-enable import/no-cycle */
import billing from './billing'
import invoices from './invoices'
import mobileInfo from './mobileInfo'
import upcomingInvoice from './upcomingInvoice'
import integrations from './integrations'
import discounts from './discounts'
import order from './order'

// Now Playing
import control from './control'
import proposedControl from './proposedControl'
import hasLiveViewNotification from './hasLiveViewNotification'
import clockSyncOffset from './clockSyncOffset'
import geoInfo from './geoInfo'
import pageVisibilityState from './pageVisibilityState' // NP

// Repos
import repos from './repos'
import permissions from './permissions'
import locks from './locks'
import requestedRepos from './requestedRepos'
import userProfiles from './userProfiles'
import authorRepos from './authorRepos'
import reposFollowerCount from './reposFollowerCount'
import reviews from './reviews'
import submissions from './submissions'
import requestedRepoContent from './requestedRepoContent'
import discoverLatestRepos from './discoverLatestRepos'
import reposFetched from './reposFetched'// to tell if repo request returned for Algolia sound library

// Scoresheet
import scoreSheetCustomDateRange from './scoreSheetCustomDateRange'

// Recent
import recentActivities from './recentActivities'

// Library
import sets from './library/sets'
import questions from './library/questions'
import folders from './library/folders'
import setsRefreshedTimestamp from './library/setsRefreshedTimestamp'
import questionsRefreshedTimestamp from './library/questionsRefreshedTimestamp'
import archivedQuestions from './library/archivedQuestions'
import archivedFolders from './library/archivedFolders'
import archivedSets from './library/archivedSets'
import setsPreview from './library/setsPreview'
import questionsPreview from './library/questionsPreview'
import foldersPreview from './library/foldersPreview'
import requestedSets from './requestedSets'
import requestedQuestions from './requestedQuestions'

// Polls
import questionPollsRefreshedTimestamp from './polls/questionPollsRefreshedTimestamp'
import setPollsRefreshedTimestamp from './polls/setPollsRefreshedTimestamp'
import queueQuestionPolls from './polls/queue/queueQuestionPolls'
import queueSetPolls from './polls/queue/queueSetPolls'
import historyQuestionPolls from './polls/history/historyQuestionPolls'
import historySetPolls from './polls/history/historySetPolls'

// Sections
import sections from './sections/sections'
import archivedSections from './sections/archivedSections'
import sectionAuthorizations from './sections/sectionAuthorizations'
import sectionPresenceMembers from './sections/sectionPresenceMembers'
import sectionControls from './sections/sectionControls'

// Symbols
import recentSymbols from './symbols/recentSymbols'
import symbolsList from './symbols/symbolsList'

// Misc
import modals from './modals'
import notification from './notification'
import onboardingWizardDismissed from './onboardingWizardDismissed'
import searchKey from './searchKey'
import backToSchoolModal from './backToSchoolModal'
import previewItem from './previewItem'// for showing item preview modal in library on press spacebar
import editorSettings from './editorSettings' // AutoAlgebra
import imageUploads from './imageUploads'// save upload result with nodeId for undo/redo in editor
import clientPlaybackCommand from './clientPlaybackCommand'
import dataOwners from './dataOwners'

// Admin
import adminRepos from './admin/adminRepos'
import adminCreators from './admin/adminCreators'
import adminSubmissions from './admin/adminSubmissions'
import adminAwards from './admin/adminAwards'

import autoplayFailedAlert from './autoplayFailedAlert'
import autoplaySetupModal from './autoplaySetupModal'
import configuration from './configuration'

const rootReducer = combineReducers({
  userConfig,
  configuration,
  autoplaySetupModal,
  integrations,
  recentSymbols,
  symbolsList,
  autoplayFailedAlert,
  reposFetched,
  clientPlaybackCommand,
  dataOwners,
  pageVisibilityState,
  previewItem,
  sectionAuthorizations,
  discoverLatestRepos,
  sectionPresenceMembers,
  submissions,
  proposedControl,
  adminAwards,
  adminSubmissions,
  adminRepos,
  adminCreators,
  service,
  reviews,
  authorRepos,
  reposFollowerCount,
  userProfiles,
  requestedRepos,
  requestedSets,
  requestedQuestions,
  order,
  backToSchoolModal,
  setsPreview,
  questionsPreview,
  foldersPreview,
  locks,
  searchKey,
  repos,
  permissions,
  discounts,
  upcomingInvoice,
  clockSyncOffset,
  mobileInfo,
  billing,
  invoices,
  planRestrictions,
  scoreSheetCustomDateRange,
  hasLiveViewNotification,
  questionPollsRefreshedTimestamp,
  setPollsRefreshedTimestamp,
  notification,
  meta,
  sectionControls,
  signinMethods,
  archivedSections,
  folders,
  archivedFolders,
  sets,
  setsRefreshedTimestamp,
  archivedSets,
  questions,
  questionsRefreshedTimestamp,
  archivedQuestions,
  queueQuestionPolls,
  queueSetPolls,
  historyQuestionPolls,
  historySetPolls,
  control,
  geoInfo,
  onboardingWizardDismissed,
  session,
  form,
  user,
  sections,
  modals,
  recentActivities,
  requestedRepoContent,
  editorSettings,
  imageUploads,
  routing: routerReducer,
})

export default rootReducer
