import React from 'react'
import { connect } from 'react-redux'

//Context badge shows in top nav with links to e.g. upgrade or whats new

//Cleaned out old context badge states e.g. beta signups
//For full implementation see OLD_ContextBadge.js

//Simple ContextBadge
//If user has no polls show them gettingStarted badge
//else show upgrade badge (if not already pro)
//to show Whats New badge set showWhatsNewBadge=true in buttonToDisplay

class ContextBadge extends React.Component{
	constructor(){
		super()		
		this.shouldDisplayUpgradeButton=this.shouldDisplayUpgradeButton.bind(this)
		this.buttonToDisplay=this.buttonToDisplay.bind(this)
		this.shouldDisplayGettingStartedButton=this.shouldDisplayGettingStartedButton.bind(this)
		this.shouldDisplayUpgradeButton=this.shouldDisplayUpgradeButton.bind(this)
	}

	showWhatsNew(){
		window.location.hash='whats-new'
		if(window.analytics){
			window.analytics.track('Context Menu Badge Click',{
				type:'whatsNew'
			})
		}
	}

	showUpgrade(){
		window.location.hash='upgrade'
		if(window.analytics){
			window.analytics.track('Context Menu Badge Click',{
				type:'defaultUpgrade'
			})
		}
	}

	showGettingStartedGuide(){
		const win = window.open('https://help.plickers.com/hc/en-us/categories/1260801472210-Getting-Started', '_blank')
  		win.focus()
		if(window.analytics){
			window.analytics.track('Context Menu Badge Click',{
				type:'gettingStartedGuide'
			})
		}
	}

	shouldDisplayGettingStartedButton(){
		let shouldDisplay=true
		if(this.props.historySetPolls.length >0 || this.props.historyQuestionPolls.length>0){
			shouldDisplay=false
		}
		return shouldDisplay
	}

	shouldDisplayUpgradeButton(){
		return this.props.service.product==='free'
	}

	buttonToDisplay(){
		const showPaymentFailure = false
		const showWhatsNewBadge=false
		let badgeType=null
		if(this.props.historySetPolls && this.props.historyQuestionPolls && this.props.meta && this.props.service){
			if(this.props.historySetPolls.length===0 && this.props.historyQuestionPolls.length===0){
				badgeType='gettingStarted'
			}
			else if(showWhatsNewBadge){
				badgeType='whatsNew'
			}else if(this.shouldDisplayUpgradeButton()){
				badgeType='upgrade'
			}else if(showPaymentFailure){
				badgeType='paymentFailure'
			}
		}
		return badgeType
	}

	render(){	
		const button=this.buttonToDisplay()
		if(button==='gettingStarted'){
			return(		
				<div className='contextBadge' onClick={()=>{this.showGettingStartedGuide()}}>
					Getting Started Guide
				</div>			
			)
		}else if(button==='upgrade'){
			return(		
				<div className='contextBadge' onClick={this.showUpgrade}>
					{'Upgrade to Pro'}
				</div>			
			)
		}
		else if(button==='paymentFailure'){
			return(		
				<div className='contextBadge contextBadge--error' onClick={this.showUpgrade}>
					Payment Problem
				</div>			
			)
		}else if(button==='whatsNew'){
			return(		
				<div className='contextBadge' onClick={this.showWhatsNew}>
					What's New
				</div>			
			)
		}
		else return null
	}
}

export default connect(
	(state) => ({
		meta:state.meta,
		historySetPolls:state.historySetPolls,
		historyQuestionPolls:state.historyQuestionPolls,
		service:state.service,	
	}),
	{ }
)(ContextBadge)
