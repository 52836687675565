import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import sortBy from 'lodash/sortBy'
import range from 'lodash/range'
import find from 'lodash/find'
import { Transition, animated } from 'react-spring/renderprops'
import OnboardingWizard from '../../components/onboarding/OnboardingWizard'
import { updateMeta } from '../../actions/meta'
import { emailAppLinks } from '../../actions/user'
import { dismissOnboardingWizard } from '../../actions/onboardingWizard'
import { showNewSectionModal, showBulkAddStudentsModal } from '../../actions/modals'

// Onboarding status doc
// https://docs.google.com/document/d/1VAbXWREJGz1ixXz0coTanHbDBmOB_tWhZuaSsaI0Oe8/edit#heading=h.ummzrll5q9gv

const wizardIsDisplayed = (location) => {
  if (// don't show wizard on these pages
    !(location.pathname.indexOf('/seteditor/') > -1) &&
    !(location.pathname.indexOf('/editor/') > -1) &&
    !(location.pathname.indexOf('/liveview') > -1) &&
    !(location.pathname.indexOf('/scoresheet') > -1) &&
    !(location.pathname.indexOf('/classRoster/') > -1) &&
    !(location.pathname.indexOf('/integration/') > -1) &&
    !(location.pathname === '/classRoster/') &&
    !(location.pathname.indexOf('/confirm-email') > -1) &&
    !(location.pathname.indexOf('/reset-password') > -1) &&
    !(location.pathname.indexOf('/delete-account') > -1) &&
    !(location.pathname.indexOf('/scan-helper') > -1) &&
    !(location.pathname.indexOf('/whatsnew') > -1)
  ) return true
  return false
}

class OnboardingWizardContainer extends Component {
  constructor(props) {
    super(props)
    this.updateCardsPreparedStatus = this.updateCardsPreparedStatus.bind(this)
    this.navigateToSection = this.navigateToSection.bind(this)
    this.resetOnboardingStatus = this.resetOnboardingStatus.bind(this)
  }

  calculatePercentageComplete = (onboardingStatus) => {
    const totalStepsCount = 4
    let completedStepsCount = 0
    if (onboardingStatus.mobileSignedIn) {
      completedStepsCount += 1
    }
    if (onboardingStatus.contentCreated) {
      completedStepsCount += 1
    }
    if (onboardingStatus.sectionCreated && onboardingStatus.studentCreated) {
      completedStepsCount += 1
    }
    if (onboardingStatus.cardsPrepared) {
      completedStepsCount += 1
    }
    const percentComplete = (completedStepsCount / totalStepsCount) * 100
    return percentComplete
  }

  updateCardsPreparedStatus() {
    const newOnboardingStatus = this.props.meta.onboardingStatus
    newOnboardingStatus.cardsPrepared = true
    this.props.updateMeta({ onboardingStatus: newOnboardingStatus })
  }

  resetOnboardingStatus() { // for dev
    const newOnboardingStatus = this.props.meta.onboardingStatus
    newOnboardingStatus.cardsPrepared = false
    this.props.updateMeta({ onboardingStatus: newOnboardingStatus })
  }

  navigateToSection() {
    const { sections } = this.props
    const availableCards = range(1, 64)
    if (!(this.props.location.pathname.indexOf('/classes/') > -1)) {
      const sortedSections = sortBy(sections, [function (section) {
        return new Date(section.userCreated)
      }]).reverse()
      const mostRecentSection = sortedSections[0]
      this.props.history.push(`/classes/${mostRecentSection.id}`)
      setTimeout(() => {
        this.props.showBulkAddStudentsModal(mostRecentSection, availableCards)
      }, 400)
    } else {
      const res = window.location.pathname.match('/classes/(.*)')
      let sectionIdString
      if (res && res.length === 2) {
        [, sectionIdString] = res
      }
      const sectionId = sectionIdString.substring(0, 24)
      if (sectionId) {
        const currentSection = find(sections, { id: sectionId })
        this.props.showBulkAddStudentsModal(currentSection, availableCards)
      }
    }
  }

  render() {
    const { meta, onboardingWizardDismissed } = this.props
    let showOnboardingWizard = false
    if (onboardingWizardDismissed) {
      showOnboardingWizard = false
    } else if (wizardIsDisplayed(this.props.location)) {
      showOnboardingWizard = true
    }

    if (
      meta &&
      meta.onboardingStatus &&
      !meta.onboardingStatus.assignedResponseScanned &&
      !meta.onboardingStatus.assignedResponseSubmitted
    ) {
      const percentComplete = this.calculatePercentageComplete(meta.onboardingStatus)
      return (
        <React.Fragment>
          <Transition
            native
            items={showOnboardingWizard}
            from={{ opacity: 0, transform: `translateY(${70}px)` }}
            enter={{ opacity: 1, transform: `translateY(${0}px)` }}
            leave={{ opacity: 0, transform: `translateY(${70}px)` }}
          >
            {(showOnboardingWizard) => showOnboardingWizard && (
              (props) => (
                <animated.div style={props} className='onboardingWizard'>
                  <OnboardingWizard
                    resetOnboardingStatus={this.resetOnboardingStatus}
                    dismissOnboardingWizard={this.props.dismissOnboardingWizard}
                    geoInfo={this.props.geoInfo}
                    percentComplete={percentComplete}
                    navigateToSection={this.navigateToSection}
                    onboardingStatus={meta.onboardingStatus}
                    updateCardsPreparedStatus={this.updateCardsPreparedStatus}
                    emailAppLinks={() => { this.props.emailAppLinks(this.props.currentUserId) }}
                    showNewSectionModal={this.props.showNewSectionModal}
                  />
                </animated.div>
              )
            )}
          </Transition>
        </React.Fragment>
      )
    } return null
  }
}

export default withRouter(connect(
  (state) => ({
    meta: state.meta,
    currentUserId: state.user.id,
    sections: state.sections,
    geoInfo: state.geoInfo,
    onboardingWizardDismissed: state.onboardingWizardDismissed,
  }),
  {
    updateMeta,
    emailAppLinks,
    showNewSectionModal,
    showBulkAddStudentsModal,
    dismissOnboardingWizard,
  },
)(OnboardingWizardContainer))
